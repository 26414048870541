import React, { useEffect, useState } from "react";
import PocketBase from "pocketbase";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";

const dateFormatOptions = {
  year: "numeric",
  month: "short",
  day: "numeric",
  timeZone: "UTC",
};

// Configuración de PocketBase
const pb = new PocketBase(process.env.REACT_APP_POCKETBASE_URL);

function Dashboard({ authToken, setAuthToken }) {
  const [historias, setHistorias] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [age, setAge] = useState("");
  const [ageFilterType, setAgeFilterType] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalAlertIsOpen, setAlertModalIsOpen] = useState(false);
  const [selectedHistoria, setSelectedHistoria] = useState(null);
  const [orden, setOrden] = useState("");
  const [alertMessage, setAlertMessage] = useState(false);
  const [nombre, setNombre] = useState("");
  const [dni, setDni] = useState("");
  const [fechaNacimiento, setFechaNacimiento] = useState("");
  const navigate = useNavigate();
  const itemsPerPage = 10;
  const [modalChangePasswordIsOpen, setModalChangePasswordIsOpen] =
    useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [changePasswordError, setChangePasswordError] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest(".relative")) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (!authToken) {
      navigate("/");
    } else {
      pb.authStore.save(authToken); // Guardar el token de autenticación
      fetchHistorias(page, searchTerm, age, ageFilterType);
    }
  }, [authToken, page, searchTerm, age, ageFilterType, navigate]);

  const fetchHistorias = async (currentPage, term, ageValue, filterType) => {
    try {
      let filter = `(nombre_y_apellido~'${term}' || dni~'${term}')`;

      if (ageValue) {
        const today = new Date().toISOString().split("T")[0];
        const ageDate = new Date(
          new Date().setFullYear(new Date().getFullYear() - ageValue)
        )
          .toISOString()
          .split("T")[0];

        if (filterType === "mayores") {
          filter += ` && (fecha_nacimiento <= '${ageDate}')`;
        } else if (filterType === "menores") {
          filter += ` && (fecha_nacimiento >= '${ageDate}')`;
        }
      }

      const result = await pb
        .collection("historias_clinicas")
        .getList(currentPage, itemsPerPage, {
          sort: "orden", // Ordenar por el campo "orden"
          filter,
        });

      setHistorias(result.items);
      setTotalPages(result.totalPages);
    } catch (error) {
      console.error("Error al obtener historias clínicas:", error);
    }
  };

  const handleLogout = () => {
    pb.authStore.clear();
    setAuthToken(null);
    navigate("/");
  };

  const handleClearSearch = () => {
    setSearchTerm("");
    setAge("");
    setAgeFilterType("");
    setPage(1);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setPage(1);
  };

  const handleAgeChange = (e) => {
    setAge(e.target.value);
    setPage(1);
  };

  const handleFilterByAge = (type) => {
    setAgeFilterType(type);
    setPage(1);
  };

  const handlePreviousPage = () => {
    if (page > 1) setPage(page - 1);
  };

  const handleNextPage = () => {
    if (page < totalPages) setPage(page + 1);
  };

  const getNextOrden = async () => {
    console.log("buscando siguiente");
    let latest;
    try {
      latest = await pb.collection("historias_clinicas").getFirstListItem("", {
        sort: "-orden", // Orden descendente para obtener el mayor orden
      });
    } catch (error) {
      console.error("Error al obtener ultima historia clinica:", error);
    }
    console.log("siguiente:");
    console.log(latest);
    return latest ? latest.orden + 1 : 1;
  };

  const openModal = async (historia = null) => {
    setSelectedHistoria(historia);
    setNombre(historia ? historia.nombre_y_apellido : "");
    setDni(historia ? historia.dni : "");
    try {
      console.log(historia.fecha_nacimiento);
      setFechaNacimiento(
        historia.fecha_nacimiento != ""
          ? new Date(historia.fecha_nacimiento)
          : new Date()
      );
    } catch (error) {
      console.error("Error al cargar fecha:", error);
    }
    const initialOrden = historia ? historia.orden : await getNextOrden();
    setOrden(historia ? historia.orden : initialOrden);

    // Suscribirse a la colección
    if (!historia) {
      console.log("Sin historia");
      pb.collection("historias_clinicas").subscribe("*", async (e) => {
        if (e.action === "create" || e.action === "update") {
          console.log();
          const nuevoOrden = await getNextOrden();
          console.log(initialOrden, nuevoOrden);
          if (nuevoOrden != initialOrden) {
            setAlertMessage(true);
          }
          setOrden(nuevoOrden);
        }
      });
    }
    setModalIsOpen(true);
    console.log(alertMessage);
  };

  const openAlertModal = (historia = null) => {
    setSelectedHistoria(historia);
    setAlertModalIsOpen(true);
  };

  const closeModal = () => {
    setAlertMessage(false);
    pb.collection("historias_clinicas").unsubscribe("*");
    setModalIsOpen(false);
  };

  const closeAlertModal = () => {
    setAlertModalIsOpen(false);
  };

  const handleSaveHistoria = async () => {
    try {
      const data = {
        nombre_y_apellido: nombre,
        dni,
        fecha_nacimiento: fechaNacimiento,
        orden,
      };

      if (selectedHistoria) {
        await pb
          .collection("historias_clinicas")
          .update(selectedHistoria.id, data);
      } else {
        await pb.collection("historias_clinicas").create(data);
      }

      closeModal();
      fetchHistorias(page, searchTerm, age, ageFilterType);
    } catch (error) {
      console.error("Error al guardar la historia clínica:", error);
    }
  };

  const handleDeleteHistoria = async (id) => {
    try {
      await pb.collection("historias_clinicas").delete(id);
      fetchHistorias(page, searchTerm, age, ageFilterType);
      setAlertModalIsOpen(true);
    } catch (error) {
      console.error("Error al eliminar la historia clínica:", error);
    }
  };

  const calcularEdad = (fecha) => {
    const nacimiento = new Date(fecha);
    const hoy = new Date();
    let edad = hoy.getFullYear() - nacimiento.getFullYear();
    const mes = hoy.getMonth() - nacimiento.getMonth();
    if (mes < 0 || (mes === 0 && hoy.getDate() < nacimiento.getDate())) {
      edad--;
    }
    return edad;
  };

  const openChangePasswordModal = () => {
    setModalChangePasswordIsOpen(true);
  };

  const closeChangePasswordModal = () => {
    setModalChangePasswordIsOpen(false);
    setCurrentPassword("");
    setNewPassword("");
    setConfirmPassword("");
    setChangePasswordError("");
  };

  const handleChangePassword = async () => {
    if (newPassword !== confirmPassword) {
      setChangePasswordError("Las contraseñas no coinciden.");
      return;
    }

    //const user = pb.authStore.model;
    const user = await pb.collection("users").authRefresh();
    console.log("Usuario autenticado:", user);

    try {
      await pb.collection("users").update(user.record.id, {
        oldPassword: currentPassword,
        password: newPassword,
        passwordConfirm: confirmPassword,
      });

      alert("Contraseña actualizada con éxito.");
      closeChangePasswordModal();
    } catch (error) {
      console.error("Error al cambiar la contraseña:", error);
      setChangePasswordError(
        error?.message || "Ocurrió un error al cambiar la contraseña."
      );
    }
  };

  function InlineAlert() {
    console.log(selectedHistoria);
    if (!alertMessage || !selectedHistoria) {
      return null;
    }
    return (
      <div
        role="alert"
        class="mt-3 relative flex w-full p-3 text-sm text-white bg-red-500 rounded-md"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="2"
          stroke="currentColor"
          class="h-5 w-5 mr-2"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
          ></path>
        </svg>
        Se ha modificado el numero de orden
      </div>
    );
  }

  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-3xl font-bold">Historias Clínicas</h1>
        <div className="p-6">
          <div className="flex justify-between items-center mb-4">
            <h1 className="text-3xl font-bold">Historias Clínicas</h1>
            <div className="relative">
              <button
                className="bg-gray-200 text-black p-2 rounded"
                onClick={() => setIsDropdownOpen((prev) => !prev)}
              >
                Opciones
              </button>
              {isDropdownOpen && (
                <div className="absolute right-0 mt-2 w-48 bg-white shadow-lg rounded border">
                  <button
                    onClick={openChangePasswordModal}
                    className="block w-full text-left px-4 py-2 hover:bg-blue-500 hover:text-white"
                  >
                    Cambiar Contraseña
                  </button>
                  <button
                    onClick={handleLogout}
                    className="block w-full text-left px-4 py-2 hover:bg-red-500 hover:text-white"
                  >
                    Cerrar Sesión
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <button
        onClick={() => openModal()}
        className="bg-green-500 text-white p-2 rounded mb-4"
      >
        Nueva Historia Clínica
      </button>

      <div className="flex gap-4 mb-4">
        <input
          type="text"
          placeholder="Buscar por nombre o DNI"
          value={searchTerm}
          onChange={handleSearchChange}
          className="w-full p-2 border rounded"
        />
        <button
          onClick={handleClearSearch}
          className="bg-gray-500 text-white p-2 rounded"
        >
          Limpiar Búsqueda
        </button>
      </div>
      <div className="flex gap-4 mb-4">
        <input
          type="number"
          placeholder="Edad"
          value={age}
          onChange={handleAgeChange}
          className="w-full p-2 border rounded"
        />
        <button
          onClick={() => handleFilterByAge("mayores")}
          className="bg-blue-500 text-white p-2 rounded"
        >
          Mayores de
        </button>
        <button
          onClick={() => handleFilterByAge("menores")}
          className="bg-blue-500 text-white p-2 rounded"
        >
          Menores de
        </button>
      </div>

      <div className="mt-4">
        {historias.map((historia) => (
          <div key={historia.id} className="bg-white p-4 mb-2 shadow rounded">
            <h5 className="mb-2 text-xl font-medium leading-tight">
              {historia.nombre_y_apellido}
            </h5>
            <p>DNI: {historia.dni}</p>
            <p>
              Fecha de Nacimiento:{" "}
              {new Date(historia.fecha_nacimiento).toLocaleDateString(
                "es-ES",
                dateFormatOptions
              )}
            </p>
            <p>Edad: {calcularEdad(historia.fecha_nacimiento)}</p>
            <h5 className="mb-2 text-xl font-medium leading-tight">
              Orden: {historia.orden}
            </h5>
            <button
              onClick={() => openModal(historia)}
              className="bg-yellow-500 text-white p-2 rounded mr-2"
            >
              Editar
            </button>
            <button
              onClick={() => openAlertModal(historia)}
              className="bg-red-500 text-white p-2 rounded"
            >
              Eliminar
            </button>
          </div>
        ))}
      </div>

      <div className="flex justify-between items-center mt-4">
        {/* Botón para ir a la primera página */}
        <button
          onClick={() => setPage(1)}
          disabled={page === 1}
          className={`p-2 rounded ${
            page === 1 ? "bg-gray-300" : "bg-blue-500 text-white"
          }`}
        >
          Primera
        </button>

        {/* Botón para ir a la página anterior */}
        <button
          onClick={handlePreviousPage}
          disabled={page === 1}
          className={`p-2 rounded ${
            page === 1 ? "bg-gray-300" : "bg-blue-500 text-white"
          }`}
        >
          Anterior
        </button>

        {/* Indicador de página actual */}
        <span>
          Página {page} de {totalPages}
        </span>

        {/* Botón para ir a la página siguiente */}
        <button
          onClick={handleNextPage}
          disabled={page === totalPages}
          className={`p-2 rounded ${
            page === totalPages ? "bg-gray-300" : "bg-blue-500 text-white"
          }`}
        >
          Siguiente
        </button>

        {/* Botón para ir a la última página */}
        <button
          onClick={() => setPage(totalPages)}
          disabled={page === totalPages}
          className={`p-2 rounded ${
            page === totalPages ? "bg-gray-300" : "bg-blue-500 text-white"
          }`}
        >
          Última
        </button>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className="p-6 bg-white rounded-lg shadow-lg max-w-lg mx-auto"
      >
        <h2 className="text-2xl font-semibold mb-4">
          {selectedHistoria ? "Editar" : "Nueva"} Historia Clínica
        </h2>

        <div className="mb-4">
          <label
            htmlFor="nombre"
            className="block text-sm font-medium text-gray-700"
          >
            Nombre y Apellido
          </label>
          <input
            id="nombre"
            type="text"
            placeholder="Nombre y Apellido"
            value={nombre}
            onChange={(e) => setNombre(e.target.value)}
            className="mt-1 p-2 w-full border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
          />
        </div>

        <div className="mb-4">
          <label
            htmlFor="dni"
            className="block text-sm font-medium text-gray-700"
          >
            DNI
          </label>
          <input
            id="dni"
            type="text"
            placeholder="DNI"
            value={dni}
            onChange={(e) => setDni(e.target.value)}
            className="mt-1 p-2 w-full border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
          />
        </div>

        <div className="mb-4">
          <label
            htmlFor="fechaNacimiento"
            className="block text-sm font-medium text-gray-700"
          >
            Fecha de Nacimiento
          </label>
          <input
            id="fechaNacimiento"
            type="date"
            placeholder="Fecha de Nacimiento"
            value={
              fechaNacimiento
                ? new Date(fechaNacimiento).toISOString().split("T")[0]
                : ""
            }
            onChange={(e) => setFechaNacimiento(e.target.value)}
            className="mt-1 p-2 w-full border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
          />
        </div>

        <div className="mb-4">
          <label
            htmlFor="orden"
            className="block text-sm font-medium text-gray-700"
          >
            Orden
          </label>
          <input
            id="orden"
            type="text"
            placeholder="Orden"
            value={orden}
            onChange={(e) => setOrden(e.target.value)} // Aquí se maneja el cambio de "orden"
            className="mt-1 p-2 w-full border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
          />
          <InlineAlert />
        </div>

        <div className="flex justify-end gap-4 mt-6">
          <button
            onClick={handleSaveHistoria}
            className="px-4 py-2 bg-blue-500 text-white font-semibold rounded-md hover:bg-blue-600 focus:ring-2 focus:ring-blue-500"
          >
            Guardar
          </button>
          <button
            onClick={closeModal}
            className="px-4 py-2 bg-gray-200 text-gray-700 font-semibold rounded-md hover:bg-gray-300 focus:ring-2 focus:ring-gray-300"
          >
            Cancelar
          </button>
        </div>
      </Modal>

      <Modal
        isOpen={modalAlertIsOpen}
        onRequestClose={closeAlertModal}
        className="p-6 bg-white rounded-lg shadow-lg max-w-lg mx-auto"
      >
        <h2 className="text-2xl font-semibold mb-4">
          Eliminar Historia Clínica
        </h2>
        <p>Está seguro que desea eliminar la historia clínica de {nombre}</p>
        <div className="flex justify-end gap-4 mt-6">
          <button
            onClick={() => handleDeleteHistoria(selectedHistoria.id)}
            className="px-4 py-2 bg-red-500 text-white font-semibold rounded-md hover:bg-red-600 focus:ring-2 focus:ring-blue-500"
          >
            Eliminar
          </button>
          <button
            onClick={closeAlertModal}
            className="px-4 py-2 bg-gray-200 text-gray-700 font-semibold rounded-md hover:bg-gray-300 focus:ring-2 focus:ring-gray-300"
          >
            Cancelar
          </button>
        </div>
      </Modal>
      <Modal
        isOpen={modalChangePasswordIsOpen}
        onRequestClose={closeChangePasswordModal}
        className="p-6 bg-white rounded-lg shadow-lg max-w-lg mx-auto"
      >
        <h2 className="text-2xl font-semibold mb-4">Cambiar Contraseña</h2>
        <div className="mb-4">
          <label
            htmlFor="currentPassword"
            className="block text-sm font-medium text-gray-700"
          >
            Contraseña Actual
          </label>
          <input
            id="currentPassword"
            type="password"
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
            className="mt-1 p-2 w-full border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
          />
        </div>

        <div className="mb-4">
          <label
            htmlFor="newPassword"
            className="block text-sm font-medium text-gray-700"
          >
            Nueva Contraseña
          </label>
          <input
            id="newPassword"
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            className="mt-1 p-2 w-full border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
          />
        </div>

        <div className="mb-4">
          <label
            htmlFor="confirmPassword"
            className="block text-sm font-medium text-gray-700"
          >
            Confirmar Nueva Contraseña
          </label>
          <input
            id="confirmPassword"
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            className="mt-1 p-2 w-full border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
          />
        </div>

        {changePasswordError && (
          <p className="text-red-500 text-sm mb-4">{changePasswordError}</p>
        )}

        <div className="flex justify-end gap-4">
          <button
            onClick={handleChangePassword}
            className="px-4 py-2 bg-blue-500 text-white font-semibold rounded-md hover:bg-blue-600 focus:ring-2 focus:ring-blue-500"
          >
            Guardar
          </button>
          <button
            onClick={closeChangePasswordModal}
            className="px-4 py-2 bg-gray-200 text-gray-700 font-semibold rounded-md hover:bg-gray-300 focus:ring-2 focus:ring-gray-300"
          >
            Cancelar
          </button>
        </div>
      </Modal>
    </div>
  );
}

export default Dashboard;
