import React, { useEffect, useState } from "react";
import PocketBase from 'pocketbase';
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";

const pb = new PocketBase(process.env.REACT_APP_POCKETBASE_URL);
const dateFormatOptions = {
  year: "numeric",
  month: "short",
  day: "numeric",
  timeZone: "UTC",
};

function Dash2({ authToken, setAuthToken }) {
  const [historias, setHistorias] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [age, setAge] = useState("");
  const [ageFilterType, setAgeFilterType] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalAlertIsOpen, setAlertModalIsOpen] = useState(false);
  const [selectedHistoria, setSelectedHistoria] = useState(null);
  const [orden, setOrden] = useState("");
  const [nombre, setNombre] = useState("");
  const [dni, setDni] = useState("");
  const [fechaNacimiento, setFechaNacimiento] = useState("");
  const [sortField, setSortField] = useState("orden"); // Estado para el campo de ordenamiento
  const navigate = useNavigate();
  const itemsPerPage = 10;

  useEffect(() => {
    if (!authToken) {
      navigate("/");
    } else {
      pb.authStore.save(authToken);
      fetchHistorias(page, searchTerm, age, ageFilterType, sortField);
    }
  }, [authToken, page, searchTerm, age, ageFilterType, sortField, navigate]);

  const fetchHistorias = async (currentPage, term, ageValue, filterType, sortBy) => {
    try {
      let filter = `(nombre_y_apellido~'${term}' || dni~'${term}')`;

      if (ageValue) {
        const today = new Date().toISOString().split("T")[0];
        const ageDate = new Date(new Date().setFullYear(new Date().getFullYear() - ageValue))
          .toISOString()
          .split("T")[0];

        if (filterType === "mayores") {
          filter += ` && (fecha_nacimiento <= '${ageDate}')`;
        } else if (filterType === "menores") {
          filter += ` && (fecha_nacimiento >= '${ageDate}')`;
        }
      }

      // Determina el orden de los resultados basado en el campo de orden seleccionado
      const sortOrder = sortBy === "edad" ? "fecha_nacimiento" : sortBy;

      const response = await pb.collection("historias_clinicas").getList(currentPage, itemsPerPage, {
        filter,
        sort: sortOrder,
      });

      setHistorias(response.items);
      setTotalPages(response.totalPages);
    } catch (error) {
      console.error("Error al obtener historias clínicas:", error);
    }
  };

  const handleSortChange = (field) => {
    setSortField(field);
    setPage(1); // Reinicia la paginación al cambiar el criterio de orden
  };

  const getNextOrden = async () => {
    const response = await pb.collection("historias_clinicas").getList(1, 1, {
      sort: "-orden",
      skipTotal: true,
    });
    return response.items[0].orden + 1;
  };

  const openModal = async (historia = null) => {
    setSelectedHistoria(historia);
    setNombre(historia ? historia.nombre_y_apellido : "");
    setDni(historia ? historia.dni : "");
    setFechaNacimiento(historia ? new Date(historia.fecha_nacimiento) : "");
    setOrden(historia ? historia.orden : await getNextOrden());
    setModalIsOpen(true);
  };

  const openAlertModal = (historia = null) => {
    setSelectedHistoria(historia);
    setNombre(historia ? historia.nombre_y_apellido : "");
    setDni(historia ? historia.dni : "");
    setFechaNacimiento(historia ? new Date(historia.fecha_nacimiento) : "");
    setOrden(historia ? historia.orden : "");
    setAlertModalIsOpen(true);
  };

  const closeModal = () => setModalIsOpen(false);
  const closeAlertModal = () => setAlertModalIsOpen(false);

  const handleSaveHistoria = async () => {
    try {
      const data = {
        nombre_y_apellido: nombre,
        dni,
        fecha_nacimiento: fechaNacimiento,
        orden,
      };

      if (selectedHistoria) {
        await pb.collection("historias_clinicas").update(selectedHistoria.id, data);
      } else {
        await pb.collection("historias_clinicas").create(data);
      }

      closeModal();
      fetchHistorias(page, searchTerm, age, ageFilterType, sortField);
    } catch (error) {
      console.error("Error al guardar la historia clínica:", error);
    }
  };

  const handleDeleteHistoria = async (id) => {
    try {
      await pb.collection("historias_clinicas").delete(id);
      fetchHistorias(page, searchTerm, age, ageFilterType, sortField);
    } catch (error) {
      console.error("Error al eliminar la historia clínica:", error);
    }
  };

  const calcularEdad = (fecha) => {
    const nacimiento = new Date(fecha);
    const hoy = new Date();
    let edad = hoy.getFullYear() - nacimiento.getFullYear();
    const mes = hoy.getMonth() - nacimiento.getMonth();
    if (mes < 0 || (mes === 0 && hoy.getDate() < nacimiento.getDate())) {
      edad--;
    }
    return edad;
  };

  return (
    <div>
      <div className="sort-controls">
        <label>Ordenar por:</label>
        <button onClick={() => handleSortChange("orden")}>Orden</button>
        <button onClick={() => handleSortChange("edad")}>Edad</button>
        <button onClick={() => handleSortChange("created")}>Fecha de Creación</button>
      </div>

      {/* Aquí va el resto del código JSX para renderizar el dashboard y las historias */}
      
      {/* Ejemplo de tabla para mostrar las historias */}
      <table>
        <thead>
          <tr>
            <th>Nombre y Apellido</th>
            <th>DNI</th>
            <th>Fecha de Nacimiento</th>
            <th>Orden</th>
            <th>Fecha de Creación</th>
          </tr>
        </thead>
        <tbody>
          {historias.map((historia) => (
            <tr key={historia.id}>
              <td>{historia.nombre_y_apellido}</td>
              <td>{historia.dni}</td>
              <td>{new Date(historia.fecha_nacimiento).toLocaleDateString("es-ES", dateFormatOptions)}</td>
              <td>{historia.orden}</td>
              <td>{new Date(historia.created).toLocaleDateString("es-ES", dateFormatOptions)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Dash2;
