import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";

const dateFormatOptions = {
  year: "numeric",
  month: "short",
  day: "numeric",
  timeZone: "UTC",
};

function Dashboard({ authToken, setAuthToken }) {
  const [historias, setHistorias] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [age, setAge] = useState("");
  const [ageFilterType, setAgeFilterType] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalAelrtIsOpen, setAlertModalIsOpen] = useState(false);
  const [selectedHistoria, setSelectedHistoria] = useState(null);
  const [orden, setOrden] = useState(""); // Estado para el orden
  const [nombre, setNombre] = useState("");
  const [dni, setDni] = useState("");
  const [fechaNacimiento, setFechaNacimiento] = useState("");
  const navigate = useNavigate();
  const itemsPerPage = 10;

  useEffect(() => {
    if (!authToken) {
      navigate("/");
    } else {
      fetchHistorias(page, searchTerm, age, ageFilterType);
    }
  }, [authToken, page, searchTerm, age, ageFilterType, navigate]);

  const fetchHistorias = async (currentPage, term, ageValue, filterType) => {
    try {
      let filter = `(nombre_y_apellido~'${term}' || dni~'${term}')`;

      if (ageValue) {
        const today = new Date().toISOString().split("T")[0];
        const ageDate = new Date(
          new Date().setFullYear(new Date().getFullYear() - ageValue)
        )
          .toISOString()
          .split("T")[0];

        if (filterType === "mayores") {
          filter += ` && (fecha_nacimiento <= '${ageDate}')`;
        } else if (filterType === "menores") {
          filter += ` && (fecha_nacimiento >= '${ageDate}')`;
        }
      }

      const url = `${
        process.env.REACT_APP_POCKETBASE_URL
      }/api/collections/historias_clinicas/records?page=${currentPage}&perPage=${itemsPerPage}&sort=orden&filter=${encodeURIComponent(
        filter
      )}`;

      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${authToken}` },
      });

      setHistorias(response.data.items);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error("Error al obtener historias clínicas:", error);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("authToken");
    setAuthToken(null);
    navigate("/");
  };

  const handleClearSearch = () => {
    setSearchTerm("");
    setAge("");
    setAgeFilterType("");
    setPage(1);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setPage(1);
  };

  const handleAgeChange = (e) => {
    setAge(e.target.value);
    setPage(1);
  };

  const handleFilterByAge = (type) => {
    setAgeFilterType(type);
    setPage(1);
  };

  const handlePreviousPage = () => {
    if (page > 1) setPage(page - 1);
  };

  const handleNextPage = () => {
    if (page < totalPages) setPage(page + 1);
  };

  const getNextOrden = async () => {
    console.log("La mami")
    let ultimoOrden;
    const url = `${process.env.REACT_APP_POCKETBASE_URL}/api/collections/historias_clinicas/records?page=1&perPage=1&sort=-orden&skipTotal=true`;
    const response = await axios.get(url, {
      headers: { Authorization: `Bearer ${authToken}` },
    });
    return response.data.items[0].orden + 1;
  };

  const openModal = async (historia = null) => {
    console.log(getNextOrden())
    setSelectedHistoria(historia);
    setNombre(historia ? historia.nombre_y_apellido : "");
    setDni(historia ? historia.dni : "");
    setFechaNacimiento(historia ? new Date(historia.fecha_nacimiento) : "");
    setOrden(historia ? historia.orden : await getNextOrden());
    setModalIsOpen(true);
  };

  const openAlertModal = (historia = null) => {
    setSelectedHistoria(historia);
    setNombre(historia ? historia.nombre_y_apellido : "");
    setDni(historia ? historia.dni : "");
    setFechaNacimiento(historia ? new Date(historia.fecha_nacimiento) : "");
    setOrden(historia ? historia.orden :"");
    setAlertModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };
  const closeAlertModal = () => {
    setAlertModalIsOpen(false);
  };

  const handleSaveHistoria = async () => {
    try {
      const data = {
        nombre_y_apellido: nombre,
        dni,
        fecha_nacimiento: fechaNacimiento,
        orden,
      };

      if (selectedHistoria) {
        await axios.patch(
          `${process.env.REACT_APP_POCKETBASE_URL}/api/collections/historias_clinicas/records/${selectedHistoria.id}`,
          data,
          { headers: { Authorization: `Bearer ${authToken}` } }
        );
      } else {
        await axios.post(
          `${process.env.REACT_APP_POCKETBASE_URL}/api/collections/historias_clinicas/records`,
          data,
          { headers: { Authorization: `Bearer ${authToken}` } }
        );
      }

      closeModal();
      fetchHistorias(page, searchTerm, age, ageFilterType);
    } catch (error) {
      console.error("Error al guardar la historia clínica:", error);
    }
  };

  const handleDeleteHistoria = async (id) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_POCKETBASE_URL}/api/collections/historias_clinicas/records/${id}`,
        { headers: { Authorization: `Bearer ${authToken}` } }
      );

      fetchHistorias(page, searchTerm, age, ageFilterType);
    } catch (error) {
      console.error("Error al eliminar la historia clínica:", error);
    }
  };

  const calcularEdad = (fecha) => {
    const nacimiento = new Date(fecha);
    const hoy = new Date();
    let edad = hoy.getFullYear() - nacimiento.getFullYear();
    const mes = hoy.getMonth() - nacimiento.getMonth();
    if (mes < 0 || (mes === 0 && hoy.getDate() < nacimiento.getDate())) {
      edad--;
    }
    return edad;
  };

  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-3xl font-bold">Historias Clínicas</h1>
        <button
          onClick={handleLogout}
          className="bg-red-500 text-white p-2 rounded"
        >
          Cerrar Sesión
        </button>
      </div>

      <button
        onClick={() => openModal()}
        className="bg-green-500 text-white p-2 rounded mb-4"
      >
        Nueva Historia Clínica
      </button>

      <div className="flex gap-4 mb-4">
        <input
          type="text"
          placeholder="Buscar por nombre o DNI"
          value={searchTerm}
          onChange={handleSearchChange}
          className="w-full p-2 border rounded"
        />
        <button
          onClick={handleClearSearch}
          className="bg-gray-500 text-white p-2 rounded"
        >
          Limpiar Búsqueda
        </button>
      </div>
      <div className="flex gap-4 mb-4">
        <input
          type="number"
          placeholder="Edad"
          value={age}
          onChange={handleAgeChange}
          className="w-full p-2 border rounded"
        />
        <button
          onClick={() => handleFilterByAge("mayores")}
          className="bg-blue-500 text-white p-2 rounded"
        >
          Mayores de
        </button>
        <button
          onClick={() => handleFilterByAge("menores")}
          className="bg-blue-500 text-white p-2 rounded"
        >
          Menores de
        </button>
      </div>

      <div className="mt-4">
        {historias.map((historia) => (
          <div key={historia.id} className="bg-white p-4 mb-2 shadow rounded">
            <h5 className="mb-2 text-xl font-medium leading-tight">
              {historia.nombre_y_apellido}
            </h5>
            <p>DNI: {historia.dni}</p>
            <p>
              Fecha de Nacimiento:{" "}
              {new Date(historia.fecha_nacimiento).toLocaleDateString(
                "es-ES",
                dateFormatOptions
              )}
            </p>
            <p>Edad: {calcularEdad(historia.fecha_nacimiento)}</p>
            <h5 className="mb-2 text-xl font-medium leading-tight">
              Orden: {historia.orden}
            </h5>
            <button
              onClick={() => openModal(historia)}
              className="bg-yellow-500 text-white p-2 rounded mr-2"
            >
              Editar
            </button>
            <button
              onClick={() => openAlertModal(historia)}
              className="bg-red-500 text-white p-2 rounded"
            >
              Eliminar
            </button>
          </div>
        ))}
      </div>

      <div className="flex justify-between items-center mt-4">
        <button
          onClick={handlePreviousPage}
          disabled={page === 1}
          className={`p-2 rounded ${
            page === 1 ? "bg-gray-300" : "bg-blue-500 text-white"
          }`}
        >
          Anterior
        </button>
        <span>
          Página {page} de {totalPages}
        </span>
        <button
          onClick={handleNextPage}
          disabled={page === totalPages}
          className={`p-2 rounded ${
            page === totalPages ? "bg-gray-300" : "bg-blue-500 text-white"
          }`}
        >
          Siguiente
        </button>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className="p-6 bg-white rounded-lg shadow-lg max-w-lg mx-auto"
      >
        <h2 className="text-2xl font-semibold mb-4">
          {selectedHistoria ? "Editar" : "Nueva"} Historia Clínica
        </h2>

        <div className="mb-4">
          <label
            htmlFor="nombre"
            className="block text-sm font-medium text-gray-700"
          >
            Nombre y Apellido
          </label>
          <input
            id="nombre"
            type="text"
            placeholder="Nombre y Apellido"
            value={nombre}
            onChange={(e) => setNombre(e.target.value)}
            className="mt-1 p-2 w-full border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
          />
        </div>

        <div className="mb-4">
          <label
            htmlFor="dni"
            className="block text-sm font-medium text-gray-700"
          >
            DNI
          </label>
          <input
            id="dni"
            type="text"
            placeholder="DNI"
            value={dni}
            onChange={(e) => setDni(e.target.value)}
            className="mt-1 p-2 w-full border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
          />
        </div>

        <div className="mb-4">
          <label
            htmlFor="fechaNacimiento"
            className="block text-sm font-medium text-gray-700"
          >
            Fecha de Nacimiento
          </label>
          <input
            id="fechaNacimiento"
            type="date"
            placeholder="Fecha de Nacimiento"
            value={
              fechaNacimiento
                ? new Date(fechaNacimiento).toISOString().split("T")[0]
                : ""
            }
            onChange={(e) => setFechaNacimiento(e.target.value)}
            className="mt-1 p-2 w-full border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
          />
        </div>

        <div className="mb-4">
          <label
            htmlFor="orden"
            className="block text-sm font-medium text-gray-700"
          >
            Orden
          </label>
          <input
            id="orden"
            type="text"
            placeholder="Orden"
            value={orden}
            onChange={(e) => setOrden(e.target.value)} // Aquí se maneja el cambio de "orden"
            className="mt-1 p-2 w-full border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
          />
        </div>

        <div className="flex justify-end gap-4 mt-6">
          <button
            onClick={handleSaveHistoria}
            className="px-4 py-2 bg-blue-500 text-white font-semibold rounded-md hover:bg-blue-600 focus:ring-2 focus:ring-blue-500"
          >
            Guardar
          </button>
          <button
            onClick={closeModal}
            className="px-4 py-2 bg-gray-200 text-gray-700 font-semibold rounded-md hover:bg-gray-300 focus:ring-2 focus:ring-gray-300"
          >
            Cancelar
          </button>
        </div>
      </Modal>

      <Modal
        isOpen={modalAelrtIsOpen}
        onRequestClose={closeAlertModal}
        className="p-6 bg-white rounded-lg shadow-lg max-w-lg mx-auto"
      >
        <h2 className="text-2xl font-semibold mb-4">
          Eliminar Historia Clínica
        </h2>
        <p>Está seguro que desea eliminar la historia clínica de {nombre}</p>
        <div className="flex justify-end gap-4 mt-6">
          <button
            onClick={() => handleDeleteHistoria(selectedHistoria.id)}
            className="px-4 py-2 bg-red-500 text-white font-semibold rounded-md hover:bg-red-600 focus:ring-2 focus:ring-blue-500"
          >
            Eliminar
          </button>
          <button
            onClick={closeAlertModal}
            className="px-4 py-2 bg-gray-200 text-gray-700 font-semibold rounded-md hover:bg-gray-300 focus:ring-2 focus:ring-gray-300"
          >
            Cancelar
          </button>
        </div>
      </Modal>
    </div>
  );
}

export default Dashboard;
