import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import HistoriaForm from './components/HistoriaForm';
import Dash2 from './components/Dashboard2';

function App() {
  const [authToken, setAuthToken] = useState(null);

  return (
    <Router>
      <div className="min-h-screen bg-gray-100">
        <Routes>
          <Route path="/" element={<Login setAuthToken={setAuthToken} />} />
          <Route path="/dashboard" element={<Dashboard authToken={authToken} />} />
          <Route path="/historia" element={<HistoriaForm authToken={authToken} />} />
          <Route path="/2" element={<Dash2 authToken={authToken} />} />

        </Routes>
      </div>
    </Router>
  );
}

export default App;