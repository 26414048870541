import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';


function HistoriaForm({ authToken, setAuthToken }) {
  const [nombre, setNombre] = useState('');
  const [dni, setDni] = useState('');
  const [fechaNacimiento, setFechaNacimiento] = useState('');
  const [orden, setOrden] = useState('');
  const navigate = useNavigate();


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post('https://forms.cc.gob.ar/api/collections/historias_clinicas/records', {
        nombre_y_apellido: nombre,
        dni,
        fecha_nacimiento: fechaNacimiento,
        orden,

      }, {
        headers: { Authorization: `Bearer ${authToken}` }
      });
      navigate('/dashboard');
    } catch (error) {
      console.error("Error al crear historia clínica:", error);
    }

    
  };

  const handleLogout = () => {
    localStorage.removeItem('authToken');
    setAuthToken(null);
    navigate('/');
  };
  const handleBack = () => {
    navigate('/dashboard');
  };    

  return (
    <form onSubmit={handleSubmit} className="bg-gray-100 p-4 rounded mt-6">
       <div className="flex justify-between items-center mb-4">
        <span className="flex justify-between">
            <h1 className="text-3xl font-bold mr-2">Crear Historia Clínica</h1>
            <button
            onClick={handleBack}
            className="bg-blue-500 text-white p-2 rounded"
            >Volver</button>
            </span>
            <button
            onClick={handleLogout}
            className="bg-red-500 text-white p-2 rounded"
            >
            Cerrar Sesión
            </button>
      </div>
      <input
        type="text"
        placeholder="Nombre y Apellido"
        value={nombre}
        onChange={(e) => setNombre(e.target.value)}
        className="w-full p-2 border rounded mb-4"
        required
      />
      <input
        type="text"
        placeholder="DNI"
        value={dni}
        onChange={(e) => setDni(e.target.value)}
        className="w-full p-2 border rounded mb-4"
        required
      />
      <input
        type="date"
        placeholder="Fecha de Nacimiento"
        value={fechaNacimiento}
        onChange={(e) => setFechaNacimiento(e.target.value)}
        className="w-full p-2 border rounded mb-4"
      />
      <input
        type="text"
        placeholder="Orden"
        value={orden}
        onChange={(e) => setOrden(e.target.value)}
        className="w-full p-2 border rounded mb-4"
        required
      />
      <button type="submit" className="bg-green-500 text-white p-2 rounded w-full">
        Guardar
      </button>
    </form>
  );
}

export default HistoriaForm;
